import { configureStore } from '@reduxjs/toolkit'
import chatReducer from './slices/chat'
import configReducer from './slices/config'
import { TypedUseSelectorHook, useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux'


export const store = configureStore({
  reducer: {
    chat: chatReducer,
    config: configReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>

export type Dispatch = typeof store.dispatch
export const useDispatch: () => Dispatch = useAppDispatch

export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector
