import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from '../store'
const name = 'config'

const initialState: any = {
  sttEnabled: false,
  ttsText: '',
  recognizedText: '',
  sttTimeout: 1.0,
  sttSensitivity: 0.1,
};

export const configSlice = createSlice({
  name,
  initialState,
  reducers: {
    changeStt: (state, action) => {
      state.sttEnabled = action.payload
    },
    setTtsText: (state, action) => {
      state.ttsText = action.payload
    },
    setRecognizedTextToStore: (state, action) => {
      state.recognizedText = action.payload
    },
    setSttTimeout: (state, action) => {
      state.sttTimeout = action.payload
    },
    setSttSensitivity: (state, action) => {
      state.sttSensitivity = action.payload
    },
  },
});

export const {
  changeStt,
  setTtsText,
  setRecognizedTextToStore,
  setSttTimeout,
  setSttSensitivity,
} = configSlice.actions;

export const enableStt = (value: boolean) => (dispatch: Dispatch) => {
  dispatch(changeStt(value))
};


export const setRecognizedText = (value: string) => (dispatch: Dispatch) => {
  if (value) console.log('recognizedText => ', value)

  dispatch(setRecognizedTextToStore(value))
}

export const selectConfig = (state: any) => state[name]
export const selectTtsText = (state: any) => state[name].ttsText
export const selectRecognizedText = (state: any) => state[name].recognizedText
export const selectSttTimeout = (state: any) => state[name].sttTimeout
export const selectSttSensitivity = (state: any) => state[name].sttSensitivity

export default configSlice.reducer
